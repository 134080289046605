import {
  squareSVGT,
  circleSVGT,
  distressedsquareSVGT,
  distressedcircleSVGT,
  ruffledcircleSVGT,
  scallopedrectangleSVGT,
  curvedsquareSVGT,
  bookmarkSVGT
} from '../../static/svgtext'

export const ContainerSVGT = distressedcircleSVGT
export const ContainerChoice = "distressedC"
export const MainFont = 'Permanent Marker'
export const SubFont = 'Poppins'

export const HomeColor = "#469D3E"
export const BeliefsColor = "#D5CB75"
export const EventsColor = "#74BD69"
export const LiveColor = "#469D3E"
export const SermonsColor = "#263873"
export const YouthColor = "#59CDBE"
export const GivingColor = "#4F4F80"
export const ContactColor = "#6667B2"
export const ThirdPage = "Livestream (YouTube)"
export const FourthPage = "Sermons"
export const YouthName = "Youth"
export const SixthPage = 'Tithe.ly'