import React from 'react'
import facebook from '../img/social/facebook.svg'
import admin from '../img/pencil.svg'
import logo from '../../static/img/White Logo.png'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import youtube from '../img/social/youtube.svg'
import { MainFont } from '../components/Sampler'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="social">
          <div style={{display: 'flex', marginTop: '-25px', marginBottom: '20px', alignItems: 'center'}}>
            {/* <link
              href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
              rel="stylesheet"
            ></link> */}
            <img
              src={logo}
              alt="PBBC Logo"
              style={{
                width: '2.2em',
                height: '2.2em',
                lineHeight: 'none',
                display: 'inline-flex',
                marginLeft: 'auto',
                marginRight: '10px',
                marginTop: '8px'
              }}
            />
            <h1 style={{
              fontSize: '1.7em',
              color: '#dfdfdf',
              fontFamily: MainFont,
              display: 'inline-flex',
              marginRight: 'auto',
              marginTop: '8px',
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
              fontWeight: MainFont === 'Maven Pro' ? 'bold' : null
            }}>
              Poplar Branch Baptist Church
            </h1>
          </div>
          <a title="Facebook" target="_blank" href="https://www.facebook.com/PoplarBranchBC">
            <img
              src={facebook}
              alt="Facebook"
              style={{ width: '1em', height: '1em' }}
            />
          </a>
          <a title="YouTube" target="_blank" href="https://www.youtube.com/channel/UCDP7BuVQEqc_rDTZ_j3LG0Q">
            <img
              src={youtube}
              alt="YouTube"
              style={{ width: '1em', height: '1em' }}
            />
          </a>
          {/* href={window.location.href + "admin/" */}
          <a title="Admin" href={"https://www.poplarbranchbc.org/admin"}>
            <img
              src={admin}
              alt="Admin"
              style={{ width: '1em', height: '1em' }}
            />
          </a>
        </div>
        <div className="content has-text-centered" style={{ paddingBottom: '30px'}}>
          <p id='copyright'>
            {'Copyright © ' + new Date().getFullYear() + ' Poplar Branch Baptist Church / All rights reserved / Privacy Policy'}
          </p>
        </div>
      </footer>
    )
  }
}

export default Footer
